<template>
  <div class="services-on-home">
    <h1 class="homepage-title my-4">Voor wat kan je bij ons terecht?</h1>
    <v-row class="homepage-container">
      <v-col class="homepage-container-col">
        Voor de volgende problemen kan je altijd bij ons terecht:
        <ul>
          <li>Lees- en schrijfproblemen</li>
          <li>Rekenproblemen</li>
          <li>Taalproblemen</li>
          <li>Hoortraining</li>
          <li>Articulatiemoeilijkheden</li>
          <li>afwijkende slik (OMFT)</li>
        </ul>

        <v-btn class="mt-8" color="rgba(248, 237, 223, 0.9)" @click="navigateToSpeachTherapyPage()">Meer info</v-btn>
      </v-col>
      <v-col class="d-flex align-center mb-4" cols="12" md="5" offset-sm="2" sm="8">
        <v-img position="center" src="@/assets/ServicesChild.jpg"></v-img>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import {Component, Vue} from "vue-property-decorator";

@Component
export default class ServicesOnHome extends Vue {
  // Data

  // Lifecycle

  // Interface methods

  navigateToSpeachTherapyPage() {
    this.$router.push({path: "/logopedie"})
  }

  // Getters & setters
  // Helper methods
}
</script>

<style lang="scss" scoped>
@import "@/scss/index.scss";

.services-on-home {
  .homepage-container {
    .homepage-container-col {
      display: grid;
      justify-content: center;
      align-items: center;
    }

    @media only screen and (max-width: $medium-screen-bp) {

    }
  }
}

</style>
